<template>
  <div>
    <span class="mb-2 block font-light opacity-50">Price</span>
    <div v-for="(price, index) in priceOptions" :key="index">
      <Checkbox textColor="text-white" fontWeight="font-normal" hover="hover:text-violet" backgroundColor="bg-black"
        borderWidth="border" :value="price.value" :title="(price.value == 0 ? 'Free' : `$${price.value}`)" name="price"
        :checked="isItemChecked(price.value)" @on-toggle="filterByPriceRange" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Checkbox from '@/components/shared/Checkbox';
import api from '@/api'

export default {
  name: 'Price',
  components: { Checkbox },

  data() {
    return {
      ranges: [],
      priceOptions: [],
      activePrices: [],
    }
  },

  methods: {
    ...mapMutations({
      toggleActivePriceRange: "filters/toggleActivePriceRange",
      setSortBy: "filters/setSortBy",
    }),

    filterByPriceRange(range) {
      this.activePrices.push(range)
      this.toggleActivePriceRange(range.replace(',', '-'))

      if (this.activePrices.length && !this.query.sortBy) {
        this.setSortBy('price')
      }

      setTimeout(() => {
        this.$emit('filter-price-range')
      })
    },

    isItemChecked(range) {
      return this.activePrices.includes(range)
    },

    title(range) {
      range.length > 1 ? `$${range[0]} - $${range[1]}` : 'Free'
      if (range.length > 1) {
        return `$${range[0]} - $${range[1]}`
      }
      return range[0] == 0 ? 'Free' : '$401+'
    },

    getPriceOptions() {
      api.get(`/price-options`).then((res) => {
        if (res.status == 200) {
          this.priceOptions = res.data.data;
          const data = this.priceOptions.map((res) => +(res.value));
          this.priceOptions.push({ value: `${(Math.max(...data))} Plus` })
        }
      })
    },

    activePricesData() {
      const prices = this.query.prices ? this.query.prices.split(',') : [];
      return prices.map(price => +price)
    },

  },

  computed: {
    ...mapState({
      //  activePrices: state => state.filters.activePrices,
      query: state => state.filters.query
    }),
  },
  mounted() {
    this.activePrices = this.activePricesData()
    this.getPriceOptions()
  }
}
</script>
