<template>
  <div v-if="bookingOptions.length">
    <div>
      <span class="mb-2 block font-light opacity-50">Booking Options</span>
      <div v-for="(option, index) in bookingOptions" :key="index">
        <Checkbox textColor="text-white" fontWeight="font-normal" hover="hover:text-violet" backgroundColor="bg-black"
          borderWidth="border" :value="option.slug" :title="option.name" name="option"
          :checked="isItemChecked(option.slug)" @on-toggle="filterByBookingOption" />
      </div>
    </div>

    <hr class="mt-8 sm:mt-4">
  </div>
</template>

<script>
import api from '@/api'
import { mapMutations, mapState } from "vuex";
import Checkbox from '@/components/shared/Checkbox';

export default {
  name: 'BookingOptions',
  components: { Checkbox },
  props: {
    type: {
      type: String,
      defaultValue: 'guest'
    }
  },

  data() {
    return {
      bookingOptions: []
    }
  },
  watch: {
    type: function () {
      this.fetchBookingOptions()
    }
  },

  computed: {
    ...mapState({
      activeBookingOptions: state => state.filters.activeBookingOptions,
      query: state => state.filters.query,
    }),
  },

  methods: {
    ...mapMutations({
      toggleActiveBookingOptions: "filters/toggleActiveBookingOptions",
    }),

    filterByBookingOption(option) {
      this.toggleActiveBookingOptions(option)
      this.$emit('filter-booking-options')
    },

    isItemChecked(slug) {
      return this.activeBookingOptions.includes(slug)
    },

    fetchBookingOptions() {
      let type = this.$router.currentRoute.query.showing ?? 'guest';
      if (type == 'guests') type = 'guest'; // Convert 'guests' to 'guest' for API compatibility.
      if (type == 'shows') type = 'show';

      api.get(`/booking-options?filter[withPrices]=true&filter[type]=${type}`)
        .then(({ data }) => {
          this.bookingOptions = data.data
        })
    },
  },

  created() {
    this.fetchBookingOptions()
  }
}
</script>

<style scoped>
hr {
  background: #D9DDEC;
  height: 1px;
  border: none;
  opacity: 15%;
}
</style>
