<template>
  <div class="grid grid-cols-1 gap-y-8 sm:gap-y-4 w-full p-3 mr-6 text-white h-full">
    <Showing @showing="fetch" />
    <hr>
    <SortBy @sort="fetch" />
    <hr>
    <!-- <hr v-if="showing == 'guests'"> -->
    <Price @filter-price-range="fetch" />
    <!-- <Price v-if="showing == 'guests'" @filter-price-range="fetch"/> -->
    <hr>

    <!-- <template v-if="showing == 'guests'"> -->
    <template>
      <BookingOptions @filter-booking-options="fetch" ref="bookingOptionRef" />
    </template>

    <Categories @filter-categories="fetch" />

    <Rating @filter-rating="fetch" />
    <hr>
    <Tags @filter-tags="fetch" :tags="tags" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Showing from '@/components/filters/Showing';
import SortBy from '@/components/filters/SortBy';
import Price from '@/components/filters/Price';
import Categories from '@/components/filters/Categories';
import Rating from '@/components/filters/Rating';
import Tags from '@/components/filters/Tags';
import BookingOptions from '@/components/filters/BookingOptions';

export default {
  name: 'Filters',
  components: { Showing, SortBy, Price, Categories, Rating, Tags, BookingOptions },

  data() {
    return {
     // type: this.query.showing??'guest',
    }
  },

  created() {
    this.$store.dispatch('tags/fetchGuestTags')
    this.$store.dispatch('tags/fetchShowTags')
  },

  methods: {
    fetch() {
      this.$router.push({ query: this.query })
      this.$refs.bookingOptionRef.fetchBookingOptions()
      this.$emit('fetch')
    },
  },

  computed: {
    ...mapState({
      query: state => state.filters.query,
      showing: state => state.filters.showing,
      allGuestTags: state => state.tags.allGuestTags,
      allShowTags: state => state.tags.allShowTags,
    }),

    tags() {
      return this.showing == 'guests' ? this.allGuestTags : this.allShowTags
    }
  },
}
</script>

<style scoped>
hr {
  background: #D9DDEC;
  height: 1px;
  border: none;
  opacity: 15%;
}
</style>
